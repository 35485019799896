$text-color: #595959;
$border-color: #d3d3d3;

h6 {
  color: $text-color;
  margin-bottom: 15px;
}

textarea {
  border-color: $border-color;
  color: $text-color;
}
